.container {
	max-width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

.details {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 18.5rem;
	padding: 2rem 0;
}

.details > span {
	font-size: 1.2rem;
}

.actions {
	display: flex;
	gap: 3rem;
	justify-content: center;
}

.actions button {
	font: inherit;
	cursor: pointer;
	width: 10rem;
	padding: 0.5rem 0;
	border-radius: 4px;
	background-color: var(--color-green-700);
	border: 2px solid var(--color-green-700);
	color: #fff;
}

.actions button[type='button'] {
	background-color: var(--color-gray-400);
	border-color: var(--color-gray-400);
}

@media screen and (min-width: 768px) {
	.container {
		max-width: 45rem;
		width: 40rem;
		height: 65vh;
	}

	.details {
		width: 100%;
	}

	.actions {
		flex-direction: row;
		gap: 3rem;
		justify-content: center;
	}
}
