.actions {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

.actions button {
	font: inherit;
	cursor: pointer;
	width: 10rem;
	padding: 0.5rem 0;
	border-radius: 4px;
	background-color: var(--color-gray-400);
	border: 2px solid var(--color-gray-400);
	color: #fff;
}

.actions a.active button {
	background-color: var(--color-green-700);
	border-color: var(--color-green-700);
}

@media screen and (min-width: 768px) {
	.actions {
		flex-direction: row;
		gap: 3rem;
		justify-content: center;
	}
}
