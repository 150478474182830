.banner {
	position: relative;
	width: 100%;
	height: 10rem;
	background-image: url('https://firebasestorage.googleapis.com/v0/b/foyer-rural-graye.appspot.com/o/imagesSite%2Fbanner.png?alt=media&token=44a3320b-bedb-4405-9c0f-d150b77e0991');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.banner h1 {
	display: none;
}

.title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	line-height: 3rem;
	padding: 0.8px;
	margin: 0;
	font-family: inherit;
	font-size: 1.5rem;
	font-weight: 700;
	text-align: center;
	background-color: var(--color-green-700);
	color: #ffff;
}

.title h2 {
	font-size: 2rem;
	margin: 0;
}

.infos {
	margin: 2rem 0;
	font: inherit;
	font-size: 1.8rem;
	font-weight: 700;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.banner {
		width: 100%;
		height: 20rem;
	}

	.title {
		width: 32rem;
		font-size: 3rem;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		padding: 1rem;
	}

	.title > h2 {
		font-size: inherit;
	}
}
@media screen and (max-width: 361px) {
	.title h2 {
		font-size: 1.7rem;
	}
}
