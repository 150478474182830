.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	margin: 0 auto;
}

.boardContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.mobileBoardContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.mobileBoardContainer > h2 {
	margin-bottom: 1rem;
	text-align: start;
}

.margin {
	margin: 0;
}

.img {
	width: 100%;
	height: auto;
	aspect-ratio: 16/10;
	background-position: center;
	background-size: cover;
	border: 2px solid black;
	border-radius: 4px;
}

.boardList {
	width: 100%;
	text-align: start;
	font-size: 1rem;
	margin: 1rem 0;
}

.boardList h2 {
	display: none;
	text-align: end;
	margin-bottom: 1rem;
}

.boardList ul {
	line-height: 28px;
}

.mapContainer {
	width: 100%;
}

.mapContainer h2 {
	margin-bottom: 1rem;
	text-align: start;
}

.mapContainer .img {
	width: 100%;
	height: auto;
	aspect-ratio: 4/3;
	background-image: url('https://firebasestorage.googleapis.com/v0/b/foyer-rural-graye.appspot.com/o/imagesSite%2Fcarte.png?alt=media&token=0a2fc565-99b6-4256-80ea-5d3d984c2b51');
	background-position: center;
	background-size: cover;
	border: 2px solid black;
	border-radius: 4px;
}

@media screen and (min-width: 768px) {
	.container {
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		gap: 2rem;
		margin: 0 auto;
	}

	.boardContainer {
		display: flex;
		width: 45rem;
		flex-direction: row;
	}

	.mobileBoardContainer {
		display: none;
	}

	.img {
		width: 50%;
	}

	.boardList {
		width: 50%;
		text-align: end;
		margin: 0;
	}

	.boardList > h2 {
		display: block;
	}

	.mapContainer {
		width: 35rem;
	}

	.mapContainer .img {
		width: 85%;
	}
}
