.header {
	width: 95vw;
	margin: auto;
	padding: 2rem 0 1rem;
}

.header nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 1rem;
	gap: 1.5rem;
}

.list {
	position: absolute;
	left: 0;
	top: 0;
	background-color: #fff;
	width: 0px;
	height: 100vh;
	transition: all 0.3s ease-in;
	z-index: 15;
}

.list.active {
	width: 100vw;
}

.list a {
	text-decoration: none;
	color: inherit;
}

.list a:hover,
.list a.active {
	color: var(--color-green-700);
	padding-bottom: 0.1rem;
	border-bottom: 1px solid var(--color-green-700);
}

.lists {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	margin-top: 10rem;
	visibility: hidden;
}

.list.active .lists {
	visibility: visible;
}

.lists button {
	cursor: pointer;
	font-family: inherit;
	padding: 4px 15px;
	border-radius: 4px;
	border: none;
	background-color: var(--color-gray-200);
}

.lists button:hover {
	background-color: var(--color-green-700);
	border: none;
	color: #ffff;
}

.logo {
	width: 5rem;
	height: 5rem;
	background-image: url('../assets/img/logo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.mobileMenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 16;
}

.hamburger {
	cursor: pointer;
	width: 24px;
	height: 24px;
	transition: all 0.25s;
	position: relative;
	border: none;
	background-color: #fff;
}

.hamburgerTop,
.hamburgerMiddle,
.hamburgerBottom {
	position: absolute;
	width: 24px;
	height: 3px;
	top: 0;
	left: 0;
	background: #000;
	transform: rotate(0);
	transition: all 0.5s;
}

.hamburgerMiddle {
	transform: translateY(7px);
}

.hamburgerBottom {
	transform: translateY(14px);
}

.open {
	transform: rotate(90deg);
}

.hamburgerTop.open {
	transform: rotate(45deg) translateY(6px) translateX(6px);
}

.hamburgerMiddle.open {
	display: none;
}

.hamburgerBottom.open {
	transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

@media screen and (min-width: 768px) {
	.header {
		max-width: 80rem;
		margin: auto;
		justify-content: flex-start;
	}

	.list {
		position: initial;
		display: flex;
		gap: 2rem;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		width: 100%;
		height: auto;
		background-color: #fff;
		z-index: 0;
	}

	.mobileMenu {
		display: none;
	}

	.lists {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;
		margin: 0;
		visibility: visible;
	}
}
