.card {
	position: relative;
	background-color: #fff;
	width: fit-content;
	height: fit-content;
	max-width: 90rem;
	color: #333;
	border: 2px solid black;
	border-radius: 15px;
	padding: 15px 22px;
	margin: 0 auto;
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
		0 8px 10px -6px rgb(0 0 0 / 0.1);
}

@media screen and (min-width: 768px) {
	.card {
		padding: 30px 45px;
	}
}
