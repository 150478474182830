.form {
	max-width: 18.5rem;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.1rem;
}

.form label,
.form input,
.form textarea {
	display: block;
	width: 100%;
}

.form input,
.form textarea {
	font: inherit;
	padding: 0.25rem;
}

.form p {
	width: 100%;
	margin-top: 0;
}

.date {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	justify-content: center;
}

.actions {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
}

.actions button {
	font: inherit;
	cursor: pointer;
	width: 10rem;
	padding: 0.5rem 0;
	border-radius: 4px;
	background-color: var(--color-green-700);
	border: 2px solid var(--color-green-700);
	color: #fff;
}

.actions button[type='button'] {
	background-color: var(--color-gray-400);
	border-color: var(--color-gray-400);
}

@media screen and (min-width: 768px) {
	.form {
		max-width: 45rem;
		width: 40rem;
		height: auto;
	}

	.date {
		flex-direction: row;
	}

	.actions {
		flex-direction: row;
		gap: 3rem;
		justify-content: center;
	}
}
