.form {
	width: 100%;
	height: 65vh;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	gap: 1rem;
}

.id {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80%;
	height: auto;
	gap: 0.8rem;
}

.form label,
.form input {
	display: block;
	width: 100%;
}

.form input {
	font: inherit;
	padding: 0.25rem;
}

.form p {
	width: 100%;
	margin-top: 0;
}

.actions {
	display: flex;
	gap: 3rem;
	justify-content: center;
}

.actions button {
	font: inherit;
	cursor: pointer;
	width: 10rem;
	padding: 0.5rem 0;
	border-radius: 4px;
	background-color: var(--color-green-700);
	border: 2px solid var(--color-green-700);
	color: #fff;
}

.actions button[type='button'] {
	background-color: var(--color-gray-400);
	border-color: var(--color-gray-400);
}

@media screen and (min-width: 768px) {
	.form {
		max-width: 45rem;
		width: 40rem;
		height: 65vh;
	}
}
