.event {
	width: 18rem;
	cursor: pointer;
}

.title {
	font-size: 1.2rem;
	font-weight: 700;
	margin: 0 0 1rem 0;
}

.type {
	font: inherit;
	font-size: 1.2 rem;
	text-align: start;
	text-transform: uppercase;
	margin: 0 0 0.5rem 0;
}

.details {
	margin: 0;
	font-style: italic;
	color: var(--color-gray-500);
}

.location {
	margin: 0;
	font-style: italic;
}

@media (min-width: 768px) {
	.event {
		width: 20rem;
		height: 9rem;
	}

	.title {
		font-size: 1.5rem;
	}
}
