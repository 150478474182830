* {
	box-sizing: border-box;
}

:root {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;

	color-scheme: light dark;
	color: rgba(0, 0, 0, 0.87);
	background-color: #ffff;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	--color-red-900: #cc3300;
	--color-orange-400: #ff7345;
	--color-orange-800: #de5426;
	--color-green-700: #139174;
	--color-green-400: #26deb3;

	--color-gray-100: #f4f3f1;
	--color-gray-200: #dddbd8;
	--color-gray-300: #ccc9c6;
	--color-gray-400: #aeaba7;
	--color-gray-500: #8a8784;
	--color-gray-600: #656360;
	--color-gray-700: #4b4a47;
	--color-gray-800: #31302e;
	--color-gray-900: #1f1d1b;
}

body {
	position: relative;
	height: 100vh;
	margin: 0;
	color: var(--color-gray-700);
}
main {
	margin: auto;
	width: 95vw;
	color: var(--color-gray-700);
	padding: 0;
}

main > h1 {
	width: 100%;
	text-align: center;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1 {
	margin-top: 0.5rem;
	font-size: 1.5em;
	text-align: center;
}

.card {
	display: none;
}

@media (min-width: 768px) {
	main {
		margin: auto;
		max-width: 80rem;
	}

	h1 {
		font-size: 2em;
		text-align: start;
	}

	.card {
		display: block;
	}
}
