.toggleButtonCover {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 1rem;
}

.knobs,
.layer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.button {
	position: relative;
	width: 74px;
	height: 36px;
	overflow: hidden;
}

.button label {
	display: block;
}

.button.r,
.button.r .layer {
	border-radius: 100px;
}

.checkbox {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}

.knobs {
	z-index: 2;
}

.layer {
	width: 100%;
	background-color: #fcebeb;
	transition: 0.3s ease all;
	z-index: 1;
}

#button1 .knobs:before {
	content: '';
	position: absolute;
	top: 4px;
	left: 4px;
	width: 20px;
	height: 10px;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	line-height: 1;
	padding: 9px 4px;
	background-color: #f44336;
	border-radius: 50%;
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button1 .checkbox:checked + .knobs:before {
	content: '';
	left: 42px;
	background-color: var(--color-green-700);
}

#button1 .checkbox:checked ~ .layer {
	background-color: #ebf7fc;
}

#button1 .knobs,
#button1 .knobs:before,
#button1 .layer {
	transition: 0.3s ease all;
}
