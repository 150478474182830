.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 0;
}

.container h2 {
	margin-bottom: 1rem;
}

.block {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.block p {
	display: block;
	margin: 0;
}

.block h3 {
	margin: 1.2rem 0 1.2rem 0;
}

.lists {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}
