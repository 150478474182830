.titleContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	width: 100%;
	height: 7rem;
	background-color: var(--color-green-700);
}

.titleContainer h1 {
	color: var(--color-gray-100);
}

.deskContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 1rem auto;
	gap: 1rem;
}

.title {
	display: block;
	width: 80vw;
	text-align: center;
	font-size: 1.5rem;
	cursor: pointer;
}

.deskContainer a {
	text-decoration: none;
	color: var(--color-gray-800);
}

@media screen and (min-width: 768px) {
	.title {
		width: 40vw;
	}
}
