.footer {
	position: initial;
	max-width: 80rem;
	margin: 1rem auto 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	gap: 1rem;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
}

.button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	padding: 4px 10px;
	border: 1px solid;
	border-radius: 4px;
}

.list a {
	text-decoration: none;
	color: var(--color-gray-700);
}

.social {
	padding: 0;
	border: none;
}

.infos {
	font-size: 0.9rem;
	display: flex;
	gap: 0.5rem;
}

.infos a {
	text-decoration: none;
	color: var(--color-gray-700);
}

.infos a:hover {
	text-decoration: underline;
}

@media screen and (min-width: 768px) {
	.footer {
		gap: 0.25rem;
	}

	.list {
		flex-direction: row;
		gap: 2rem;
	}
}

/* @media screen and (min-height: 930px) {
	.footer {
		position: absolute;
		bottom: 0.25rem;
		left: 50%;
		transform: translateX(-50%);
	}
} */
