.event {
	width: 100%;
	padding: 0.5rem;
	text-align: center;
	border: solid 2px var(--color-orange-800);
	color: var(--color-orange-800);
	border-radius: 3px;
	margin: 0.5rem 0;
}

.event h2 {
	margin: 0;
}

.event p {
	margin: 0;
	padding: 0.5rem;
}
