.infos {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	margin: 1rem 0;
}

.infos h1 {
	margin-bottom: 1rem;
	line-height: 38px;
	text-align: center;
}

.text {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;
	gap: 1.5rem;
}

.description {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.5rem;
}

.description p {
	margin: 0;
}

.img {
	width: 100%;
	height: auto;
	aspect-ratio: 18/10;
	background-image: url('https://firebasestorage.googleapis.com/v0/b/foyer-rural-graye.appspot.com/o/imagesSite%2F%C3%A9glise.jpg?alt=media&token=eef60f1c-6548-4601-8c60-c22fa8c3f8cb');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border: 2px solid black;
	border-radius: 4px;
}

@media screen and (min-width: 768px) {
	.infos {
		align-items: flex-start;
	}

	.infos h1 {
		width: 100%;
		text-align: center;
	}

	.text {
		flex-direction: row;
		align-items: flex-start;
		gap: 1rem;
	}

	.description {
		width: 55%;
		gap: 1rem;
	}

	.img {
		width: 37%;
	}
}
